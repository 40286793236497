import(/* webpackMode: "eager" */ "/app/client/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/app/client/components/providers/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/client/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/app/client/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/app/client/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/app/client/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/client/node_modules/next/font/local/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"../public/assets/fonts/EffraVF_W_Wght.woff2\",\"variable\":\"--font-effra\",\"display\":\"swap\"}],\"variableName\":\"effra\"}");
;
import(/* webpackMode: "eager" */ "/app/client/node_modules/next/font/local/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../public/assets/fonts/LyonDisplay-Regular-Web.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../public/assets/fonts/LyonDisplay-Bold-Web.woff2\",\"weight\":\"700\",\"style\":\"normal\"}],\"variable\":\"--font-lyon\",\"display\":\"swap\"}],\"variableName\":\"lyon\"}");
